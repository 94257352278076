export default {
data(){
    return{
        assetStatusList:[ // 资产状态
            {val:1001, name: "待出库"},
            {val:1002, name: "待发货"},
            {val:1003, name: "已发货"},
            {val:1004, name: "待装机"},
            {val:1005, name: "使用中"},
            {val:1006, name: "待返厂"},
            {val:1007, name: "返厂中"},
            // {val:1008, name: "报废中"},
            {val:1009, name: "已报废"},
            {val:1010, name: "已停产"},
            {val:1011, name: "维修中"},
            {val:1012, name: "已维修"},
            {val:1013, name: "出厂中"},
            {val:1020, name: "退回厂内"},
        ],
        assetStatusOptions:[  // 资产类型
            {val:1060, name: "使用中"},
            {val:1061, name: " 停用中(医院)"},
            {val:1062, name: " 停用中(经销商)"},
            {val:1063, name: "返回厂内"},
        ],
        timeTypeList: [  // / 筛选时间类型
            {val:101, name: "入库时间"},
            {val:102, name: "出库时间"},
            {val:103, name: "装机时间"},
        ],
        locationTagOptions:[  //仪器位置
            {val:0, name: "其他"},
            {val:1, name: "中心实验室"},
            {val:2, name: "实验室"},
            {val:3, name: "门诊"},
            {val:4, name: "发热门诊"},
            {val:5, name: "急诊"},
            {val:6, name: "门急诊"},
            {val:7, name: "儿科"},
            {val:8, name: "感染科"},
            {val:9, name: "病房检验科"},
            {val:10, name: "免疫室"},
            {val:11, name: "微生物室"},
            {val:12, name: "血液室"},
        ],
        hospitalLevelOptions:[  // 医院等级
            {val:1, name: "民营"},
            {val:2, name: "卫生院"},
            {val:3, name: "一甲"},
            {val:4, name: "一乙"},
            {val:5, name: "一丙"},
            {val:6, name: "二甲"},
            {val:7, name: "二乙"},
            {val:8, name: "二丙"},
            {val:9, name: "三特"},
            {val:10, name: "三甲"},
            {val:11, name: "三乙"},
            {val:12, name: "三丙"},
        ],
        presentLocationOptions: [  // 现存位置
            {val:1530, name: "医院"},
            {val:1531, name: "经销商"},
            {val:1532, name: "奥普"},
        ]

    }
    }
}